import React, {useEffect, useState} from "react"
import { navigate } from "gatsby"


// Components
import Layout from "../components/layout"
import ForgotPwrd from "layout/Login/LoginScreen/forgot-password"
import {SEODetails} from "../components/common/static_seo"
import {CustomLinks} from "../components/common/menuUtils"
import { isLoggedIn } from "../services/auth"

const ForgotPassword = () => {
    const [isAuth, setIsAuth] = React.useState(isLoggedIn())

    useEffect(() => {
        var redirect_link = CustomLinks.forgot_password; 
        if(isAuth){
            redirect_link = CustomLinks.dashboard;
        }
        navigate('/'+redirect_link+'/')
    },[isAuth]);

    return (
        <Layout variant={""} Meta_Title={SEODetails.forgot_pwd.title} Meta_Description={SEODetails.forgot_pwd.description}>
            <ForgotPwrd />
        </Layout>
    )
}

export default ForgotPassword